import { createRouter, RouterOptions, createWebHashHistory, createWebHistory } from "vue-router"
import login from "./app/login.vue"
import frame from "./app/frame.vue"

import contactList from "./shared/contactList.vue"
import userList from "./administration/userList.vue"
import userEdit from "./administration/userEdit.vue"
import newsList from "./administration/newsList.vue"
import landingPage from "./app/landingPage.vue"

import { routeGuards } from "../auth/routeGuards"

const routerOptions: RouterOptions = {
    history: createWebHistory("/"),
    //history: createWebHashHistory(),
    routes: [
        { path: "/", name: "rootLogin", component: login },
        { path: "/login", name: "login", component: login },
        {
            path: "/EIA",
            children: [
                {
                    path: "",
                    name: "askIris",
                    components: {
                        tabContent: () => import("./EIA/irisTestForm.vue"),
                    },
                },
            ],
        },
        {
            path: "/ai",
            name: "ai",
            component: frame,
            beforeEnter: [routeGuards.requiresLogin],

            children: [
                {
                    path: "aiChatList",
                    name: "aiChatList",
                    components: {
                        maincontent: () => import("./ai/aiChatList.vue"),
                    },
                },

                {
                    path: "aiHeroForm/:personaId&projectId=:projectId?",
                    name: "aiHeroForm",
                    components: {
                        maincontent: () => import("./ai/aiHeroForm.vue"),
                    },
                },
                {
                    path: "aiChat/:id",
                    name: "aiChat",
                    components: {
                        maincontent: () => import("./ai/aiChat.vue"),
                    },
                },
                {
                    path: "aiHeroList/:pageMode?/:recentPersonaName?/:projectId?",
                    name: "aiHeroList",
                    components: {
                        maincontent: () => import("./ai/aiHeroList.vue"),
                    },
                },
                {
                    path: "newAiChat/:personaId/:projectId?",
                    name: "newAiChat",
                    components: {
                        maincontent: () => import("./ai/aiChat.vue"),
                    },
                },
                {
                    path: "chatGPTProxy",
                    name: "chatGPTProxy",
                    components: {
                        maincontent: () => import("./ai/chatGPTProxy.vue"),
                    },
                },
            ],
        },
        {
            path: "/app",
            name: "frame",
            component: frame,
            beforeEnter: [routeGuards.requiresLogin],

            children: [
                {
                    path: "",
                    name: "landing",
                    components: {
                        maincontent: () => import("./app/landingPage.vue"),
                    },
                },
                {
                    path: "componentDemo",
                    name: "componentDemo",
                    components: { maincontent: () => import("./crispify/crispy-components/componentDemo.vue") },
                    beforeEnter: [routeGuards.requiresAdmin],
                },
                {
                    path: "helpAdmin",
                    name: "helpAdmin",
                    components: { maincontent: () => import("./administration/helpAdminList.vue") },
                    beforeEnter: [routeGuards.requiresAdmin],
                },
                {
                    path: "version",
                    name: "version",
                    components: { maincontent: () => import("./administration/version.vue") },
                    beforeEnter: [routeGuards.requiresAdmin],
                },
                {
                    path: "systemConfiguration",
                    name: "systemConfiguration",
                    components: { maincontent: () => import("./administration/systemConfiguration.vue") },
                    beforeEnter: [routeGuards.requiresAdmin],
                },
                {
                    path: "contactList",
                    name: "contactList",
                    components: { maincontent: contactList },
                    beforeEnter: [routeGuards.requiresLogin],
                },
                {
                    path: "userList",
                    name: "userList",
                    components: { maincontent: userList },
                    beforeEnter: [routeGuards.requiresLogin],
                },
                {
                    path: "clientList",
                    name: "clientList",
                    components: { maincontent: () => import("./shared/clientList.vue") },
                    beforeEnter: [routeGuards.requiresLogin],
                },

                {
                    path: "clientList",
                    name: "userEditNew",
                    components: { maincontent: userEdit },
                    beforeEnter: [routeGuards.requiresLogin],
                },
                {
                    path: "systemSetting",
                    name: "systemSetting",
                    components: { maincontent: () => import("./administration/systemSetting.vue") },
                    beforeEnter: [routeGuards.requiresAdmin],
                },
                {
                    path: "userType",
                    name: "userType",
                    components: { maincontent: () => import("./administration/userTypeList.vue") },
                    beforeEnter: [routeGuards.requiresAdmin],
                },
                {
                    path: "newsList",
                    name: "newsList",
                    components: { maincontent: newsList },
                    beforeEnter: [routeGuards.requiresAdmin],
                },
                {
                    path: "landingPage",
                    name: "landingPage",
                    components: { maincontent: landingPage },
                    beforeEnter: [routeGuards.requiresLogin],
                },
                {
                    path: "userProfile",
                    name: "userProfile",
                    components: { maincontent: () => import("./administration/userProfile.vue") },
                    beforeEnter: [routeGuards.requiresLogin],
                },
                {
                    path: "projectList/:projectId?/:activeIndex?",
                    name: "projectList",
                    components: { maincontent: () => import("./shared/projectList.vue") },
                    beforeEnter: [routeGuards.requiresLogin],
                },
            ],
        },
    ],
}

const router = createRouter(routerOptions)
export default router
