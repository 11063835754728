<template>
	<div v-tooltip.left="realtimeStatus.aiConnection.charAt(0).toUpperCase() + realtimeStatus.aiConnection.slice(1)">
		<FontAwesomeIcon :icon="['fas', getConnectionIcon]" size="lg" :class="[getConnectionClass, { outline: outline }]" />
	</div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { useRealtimeCommunicationStore } from "../../store/realtimeCommunicationStore"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faWifiExclamation, faWifi } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { boolean } from "zod"
library.add(faWifiExclamation, faWifi)

const realtimeStatus = useRealtimeCommunicationStore()

defineProps({
	outline: {
		type: Boolean,
		required: false,
	},
})

const getConnectionIcon = computed(() => {
	if (realtimeStatus.aiConnection == "connected") {
		return "wifi"
	} else if (realtimeStatus.aiConnection == "reconnecting" || realtimeStatus.aiConnection == "connecting") {
		return "wifi-exclamation"
	} else {
		return "wifi-exclamation"
	}
})

const getConnectionClass = computed(() => {
	if (realtimeStatus.aiConnection == "connected") {
		return "text-greenSignal"
	} else if (realtimeStatus.aiConnection == "reconnecting" || realtimeStatus.aiConnection == "connecting") {
		return "text-yellowSignal"
	} else {
		return "text-redSignal"
	}
})
</script>

<style scoped>
.outline {
	background-color: white;
	padding: 0.25rem;
	border-radius: 100%;
}
</style>
