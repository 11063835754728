<template>
	<div class="flex flex-col gap-0.5 bg-white shadow sm:rounded-lg p-4 m-4">
		<div v-if="!displayForm">
			<div class="w-full flex justify-between mb-4">
				<h1 class="font-bold text-2xl text-primary">Contact Management</h1>
				<button class="btn btn-primary" @click="newContact()" v-if="!displayForm & SessionInfo.hasRightsToScreen('Contacts', PageAccess.Create)">
					New
				</button>
			</div>
			<DataTable :value="persons" responsiveLayout="scroll" :row-hover="true" @row-click="rowClick($event)">
				<Column header="Name">
					<template #body="{ data }">
						<div class="w-full">{{ data.firstName + " " + data.lastName }}</div>
					</template>
				</Column>
				<Column field="emailid" header="Email Id"></Column>
			</DataTable>
		</div>
		<div v-if="displayForm">
			<contactForm :person="person" @cancel="cancelContactSave()" @refreshList="loadContactList()" />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { ApiClient, Person } from "@/../entities/NSwagGenerated"

import DataTable from "primevue/datatable"
import Column from "primevue/column"
import { axiosClient } from "@/comms/apiClient"
import axios from "axios"
import contactForm from "./contactForm.vue"
import { SessionInfo, PageAccess } from "@/auth/personSessionData"

export default defineComponent({
	name: "contactList",
	components: {
		DataTable,
		Column,
		contactForm,
	},
	data() {
		const apiClient = new ApiClient(import.meta.env.VITE_API_URL?.toString())
		const persons = new Array<Person>()
		const person = new Person()
		const displayForm: boolean = false

		return { apiClient, persons, person, displayForm, SessionInfo, PageAccess }
	},
	mounted() {
		this.loadContactList()
	},
	methods: {
		loadContactList() {
			return this.apiClient!.contactAll().then((result) => {
				this.persons = result
			})
		},
		newContact() {
			this.person = new Person()
			this.displayForm = true
		},
		rowClick(e) {
			this.apiClient.userGet(e.data.id).then(
				(result) => {
					this.person = result
					this.displayForm = true
				},
				(err) => {
					console.log(err)
				}
			)
		},
		cancelContactSave() {
			this.loadContactList().then((res) => {
				this.displayForm = false
				this.person = new Person()
			})
		},
		navTo(event: any) {
			console.log(event)
		},
	},
	computed: {},
})
</script>

<style scoped></style>
