<template>
	<span :class="alignIcon == 'left' ? 'p-input-icon-left' : 'p-input-icon-right'">
		<i class="pi pi-search" />
		<InputText :modelValue="modelValue" @update:modelValue="$emit('update:modelValue', $event)" :placeholder="placeholder" class="p-inputtext-sm" />
	</span>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = defineProps<{
	modelValue?: string
	placeholder?: string
	alignIcon?: "left" | "right"
}>()

const emit = defineEmits<{
	(e: "update:modelValue", value: string): void
}>()

const placeholder = computed(() => props.placeholder || "Keyword Search")
</script>

<style scoped>
.p-input-icon-left {
	position: relative;
	display: inline-block;
}

.p-input-icon-left i {
	position: absolute;
	top: 50%;
	left: 0.75rem;
	transform: translateY(-50%);
	z-index: 1;
}

.p-input-icon-left input {
	padding-left: 2.5rem;
	border-radius: 0 0.5rem 0.5rem 0;
}

.p-input-icon-right {
	position: relative;
	display: inline-block;
}

.p-input-icon-right i {
	position: absolute;
	top: 50%;
	right: 0.75rem;
	transform: translateY(-50%);
	z-index: 1;
}

.p-input-icon-right input {
	padding-right: 2.5rem;
	border-radius: 0 0.5rem 0.5rem 0;
}
</style>
