import { ApiClient, Client } from "@/../entities/NSwagGenerated"
import Tracker from "@openreplay/tracker"
import createAuth0Client, {
	Auth0Client,
	GetIdTokenClaimsOptions,
	GetTokenSilentlyOptions,
	GetTokenWithPopupOptions,
	LogoutOptions,
	RedirectLoginOptions,
	User,
} from "@auth0/auth0-spa-js"

//openreplay
// import Tracker from "@openreplay/tracker"
// const tracker = new Tracker({
// 	projectKey: "tGvarCxFw4VqXmsvA5hh",
// })

export abstract class appAuth {
	public static async loginToAppWithToken(token: string, cypressTest: boolean = false): Promise<string> {
		const apiClient = new ApiClient(import.meta.env.VITE_API_URL.toString())
		return apiClient.login(token).then(
			(res) => {
				if (!cypressTest) {
					//openreplay
					// const tracker = new Tracker({
					// 	projectKey: "tGvarCxFw4VqXmsvA5hh",
					// })
					// tracker.setUserID(res.email)
					// tracker.start()
				}
				sessionStorage.setItem("PersonSessionData", JSON.stringify(res))
				sessionStorage.setItem("token", token)
				return "ok"
			},
			(err) => {
				return err.toString()
			}
		)
	}
}
