<template>
	<div class="flex flex-col sideNav" :class="{ collapsed: sidebarCollapsed }">
		<div class="flex flex-col content-between gap-2 bg-primary" id="navMenu">
			<button title="Toggle Menu" @click="pinMenu()" id="expandMenu" class="flex justify-end px-5 py-2 text-2xl">
				<FontAwesomeIcon
					class="text-white fa-lg hover:backdrop-brightness-90"
					:class="{ hide: !sidebarCollapsed }"
					:icon="['far', sidebarPinned ? 'xmark' : 'bars']"
				/>
			</button>

			<nav class="flex flex-col flex-grow text-white" id="navigationLinks" role="navigation">
				<button
					@click="navto(item.href)"
					:data-href="item.href"
					v-for="item in navigation"
					:key="item.name"
					:title="item.name"
					:class="[
						item.current ? ' text-white brightness-150' : 'text-indigo-100 hover:bg-secondary hover:backdrop-brightness-90',
						'flex items-center px-4 py-2',
					]"
				>
					<FontAwesomeIcon :icon="['far', item.icon]" class="flex-shrink-0 mr-3 text-xl" aria-hidden="true" style="width: 1.25em" />

					<span class="text-sm font-medium menuItem" :class="{ hide: sidebarCollapsed }">
						{{ item.name }}
					</span>
				</button>
			</nav>

			<div class="flex flex-col flex-grow text-white px-4 py-2">
				<div class="flex flex-row justify-between align-middle">
					<a href="#" class="p-1 font-medium text-white menuItem" :class="{ hide: sidebarCollapsed }" @click="callUserProfile()">{{
						user.fullName
					}}</a>
				</div>

				<div class="flex">
					<a href="#" class="text-sm font-medium text-indigo-200 p-2 hover:text-white `" @click="logout()"
						><span class="text-sm font-medium menuItem" :class="{ hide: sidebarCollapsed }">Log Out</span></a
					>
				</div>
				<div class="flex gap-2 items-center menuItem">
					<PythonAiStatus :outline="true" /><span class="text-sm font-medium menuItem" :class="{ hide: sidebarCollapsed }"> AI Status </span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import {
	faArrowFromLeft,
	faArrowFromRight,
	faBars,
	faXmark,
	faExpand,
	faCircleUser,
	faUsers,
	faListTree,
	faFaceSmile,
	faBriefcase,
	faCircleInfo,
	faChartBar,
	faMap,
	faSunBright,
	faFlask,
	faFire,
	faGear,
	faCircleExclamation,
	faGraduationCap,
	faBookOpen,
	faTally,
	faBullhorn,
	faSplit,
	faChartMixed,
	faCircleQuestion,
	faHeadSideGear,
	faSparkle,
} from "@fortawesome/pro-regular-svg-icons"

library.add(
	faBars,
	faXmark,
	faArrowFromLeft,
	faArrowFromRight,
	faExpand,
	faListTree,
	faUsers,
	faCircleUser,
	faFaceSmile,
	faBriefcase,
	faCircleInfo,
	faChartBar,
	faMap,
	faSunBright,
	faFlask,
	faFire,
	faGear,
	faCircleExclamation,
	faGraduationCap,
	faBookOpen,
	faTally,
	faBullhorn,
	faSplit,
	faChartMixed,
	faCircleQuestion,
	faHeadSideGear,
	faSparkle
)

import { defineComponent, ref, inject } from "vue"
import AutoComplete from "primevue/autocomplete"
import { ApiClient, Client } from "@/../entities/NSwagGenerated"
import * as Session from "@/auth/personSessionData"
import { useRouter } from "vue-router"
import { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js"

export default defineComponent({
	name: "SidebarMenu",
	setup() {
		const auth0Options: Auth0ClientOptions = {
			clientId: import.meta.env.VITE_AUTH0_CLIENT_KEY,
			domain: import.meta.env.VITE_AUTH0_DOMAIN,
		}

		const auth0Client = new Auth0Client(auth0Options)

		if (sessionStorage.getItem("PersonSessionData") == null) {
			const router = useRouter()
			sessionStorage.setItem("error", "Application user information not found")
			auth0Client.logout()
		}

		let clients = ref(new Array<Client>())
		let selectedClient = ref(null)
		let filteredClients = ref(new Array<Client>())
		const apiClient = ref(new ApiClient(import.meta.env.VITE_API_URL.toString()))

		let user = Session.SessionInfo.currentUser()

		return { auth0Client, clients, filteredClients, selectedClient, apiClient, user }
	},
	components: { AutoComplete, FontAwesomeIcon },
	data() {
		let sidebarPinned = localStorage.getItem("sidebarPinned") == "true" ? true : false
		let sidebarCollapsed = !sidebarPinned
		let navigation = new Array<any>()
		const environment = import.meta.env.VITE_BASE_URL.toString()

		return { sidebarCollapsed, navigation, sidebarPinned, environment }
	},
	methods: {
		loadClientList() {
			this.apiClient.clientAll().then((clients) => {
				this.clients = clients
			})
		},
		hasAccessToScreen(screenName: string) {
			var access = this.user.screens.find((s) => s.screen.screenName == screenName)
			if (access) {
				return access.accessType!.indexOf("N") < 0
			} else {
				console.log("screen name not found for " + screenName + ` so couldn''t insert into navbar`)
				return false
			}
		},
		callUserProfile() {
			if (import.meta.env.VITE_ENVIRONMENT_NAME == "Training") {
				this.$router.push("/app/userProfile")
			} else {
				this.$router.push("/app/landingPage")
			}
		},
		logout() {
			sessionStorage.clear()
			this.auth0Client.logout({
				logoutParams: { returnTo: import.meta.env.VITE_BASE_URL.toString() },
				clientId: import.meta.env.VITE_AUTH0_CLIENT_KEY,
			})
		},
		toggleMenu(visible: boolean) {
			if (!this.sidebarPinned) {
				if (!this.sidebarCollapsed) {
					setTimeout(() => {
						this.sidebarCollapsed = visible
					}, 100)
				} else {
					this.sidebarCollapsed = visible
				}
			}
		},
		pinMenu() {
			this.sidebarPinned = !this.sidebarPinned
			localStorage.setItem("sidebarPinned", this.sidebarPinned.toString())
			this.sidebarCollapsed = !this.sidebarPinned
		},
		navto(href: string) {
			console.log(href)
			this.$router.push(href)
		},
		clearLocalStorages() {
			if (localStorage.getItem("tablePaginate")) {
				localStorage.removeItem("tablePaginate")
			}
		},
	},
	mounted() {
		if (this.user.isAdmin) {
			this.navigation.push(
				{ name: "Dashboard", icon: "chart-mixed", href: "/app/landingPage", current: false, order: 1 },
				{ name: "System Configuration", icon: "gear", href: "/app/systemConfiguration", current: false, order: 2 },
				{ name: "Users", icon: "circle-user", href: "/app/userList", current: false, order: 3 },
				{ name: "Clients", icon: "face-smile", href: "/app/clientList", current: false, order: 4 },
				{ name: "Projects", icon: "briefcase", href: "/app/projectList", current: false, order: 5 },
				{ name: "News Management", icon: "bullhorn", href: "/app/newsList", current: false, order: 15 },
				{ name: "Components", icon: "bullhorn", href: "/app/componentDemo", current: false, order: 66 },
				{ name: "ChatGPT", icon: "bullhorn", href: "/ai/chatGPTProxy", current: false, order: 99 },
				{ name: "Hero Chat", icon: "sparkle", href: "/ai/aiHeroList/chat", current: false, order: 99 },
				{ name: "Hero Builder", icon: "head-side-gear", href: "/ai/aiHeroList/build", current: false, order: 99 },
				{ name: "Chat History", icon: "bullhorn", href: "/ai/aichatList", current: false, order: 99 }
			)
		} else {
			if (this.user.screens.length > 0) {
				this.navigation.push({ name: "Projects", icon: "briefcase", href: "/app/projectList", current: false, order: 5 })
				this.user.screens.forEach((security) => {
					if (security.screen.screenName == "CL-Hero" && this.hasAccessToScreen("CL-Hero")) {
						this.navigation.push({ name: "Hero Builder", icon: "head-side-gear", href: "/ai/aiHeroList/chat", current: false, order: 15 })
					}
					if (security.screen.screenName == "CL-Hero" && this.hasAccessToScreen("CL-Hero")) {
						this.navigation.push({ name: "CL-Hero", icon: "sparkle", href: "/ai/aiChatList", current: false, order: 99 })
					}
				})
			}
			this.loadClientList()
		}
		this.navigation.sort((a, b) => a.order - b.order)
	},
})
</script>

<style scoped>
.sideNav {
	width: 300px;
	transition: all 0.5s ease-in-out;
	background: var(--primary);
}
.sideNav.collapsed {
	width: 60px;
}

.menuItem {
	transition: all 0.5s ease-in;
	white-space: nowrap;
}
#navigationLinks {
	padding-bottom: 2em;
}
#navMenu button:focus-visible,
a:focus-visible {
	background-color: var(--secondary);
	color: white;
}
.menuItem.hide {
	display: none;
}

.menuImage {
	opacity: 1;
	transition: all 0.5s ease-in-out;
}

.menuImage.hide {
	opacity: 0;
}
</style>
