<template></template>

<script setup lang="ts">
import { io } from "socket.io-client"
import { useEnvironmentSettings } from "@/composables/environmentSettings"
import { EventBus } from "@/eventBus"
import { useSocket } from "@/composables/socketio"
import { useRealtimeCommunicationStore } from "../../../store/realtimeCommunicationStore"

const store = useRealtimeCommunicationStore()
const socket = useSocket()

socket.on("connect", () => {
	console.log("realtime ai connected") // true
	store.aiConnection = "connected"
})

socket.on("disconnect", (reason) => {
	console.log("realtime ai disconnected")
	store.aiConnection = "disconnected"
	console.error(reason)
})

socket.io.on("reconnect_attempt", () => {
	socket.auth = { token: sessionStorage.getItem("token") }
	store.aiConnection = "reconnecting"
	console.log("reconnecting to realtime ai")
})

socket.io.on("reconnect", () => {
	socket.auth = { token: sessionStorage.getItem("token") }
	store.aiConnection = "connected"
	console.log("realtime ai reconnected")
})

socket.on("connect_error", () => {
	console.log("realtime ai connection error")
	store.aiConnection = "error"
	socket.connect()
})

socket.on("ping", (data) => {
	console.log(data)
	socket.emit("pong", "Hello from client")
})
</script>

<style scoped></style>
