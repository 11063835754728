<template>
	<div class="i2Panel">
		<div class="flex flex-col gap-4 mb-4 md:flex-row">
			<div class="flex flex-col w-full gap-4">
				<router-view name="tabContent"></router-view>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { defineComponent, onMounted, ref } from "vue"
import { ApiClient, News, Project, NewsPersonLog } from "@/../entities/NSwagGenerated"

import { SessionInfo, PageAccess } from "@/auth/personSessionData"
import newsFeed from "./newsCard.vue"

import { library } from "@fortawesome/fontawesome-svg-core"
import { faEnvelopeCircleCheck, faEnvelope, faCircleXmark } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { useRouter } from "vue-router"
import TabView, { TabViewClickEvent } from "primevue/tabview"
import { TabPanelProps } from "primevue/tabpanel"
import TabViewPassThroughOptions from "primevue/tabview/"
import { TabChange } from "@openreplay/tracker/lib/app/messages.gen"
import { useEnvironmentSettings } from "@/composables/environmentSettings"
library.add(faEnvelopeCircleCheck, faEnvelope)

const apiClient = new ApiClient(useEnvironmentSettings().apiURL)
const newsList = ref(new Array<News>())
const filteredList = ref(new Array<News>())
const imageFile = ref("")
const loggedInUser = SessionInfo.currentUser().fullName
const modalOpened = ref(false)
const newsId = ref("")
const loading = ref(false)
const activeTabIndex = ref(0)
const tabview = ref<TabView>()

const editing = ref(false)
const editId = ref("")

onMounted(() => {
	loadNewsList()
	useRouter().push({ name: "aiHeroList", params: { pageMode: "build" } })
})

const openRecord = (payload: { id: string }) => {
	editId.value = payload.id
	editing.value = true
}

const loadContentRoute = (e: TabViewClickEvent) => {
	console.log("clicked: " + e.index)
	console.log(tabview.value?.tabs[e.index].props.header)
	console.log(tabview.value?.tabs[e.index])
}

const testHeaderAction = (props: TabPanelProps, parent: TabView, index: number) => {
	console.log("testHeaderAction")
	console.log(props)
	console.log(parent)
	console.log(index)
}

const closeForm = () => {
	editing.value = false
	editId.value = ""
}

function navto(href: string) {
	if (useRouter().currentRoute.value.path.indexOf(href) > -1) {
		//reload page'
		useRouter().go(0)
	} else {
		useRouter().push("/app/" + href)
	}
}
function close(markRead: boolean = false) {
	modalOpened.value = false
	document.body.classList.remove("modal-open")
	if (filteredList.value.length == 1 && markRead) {
		logPersonAccess(newsId.value)
	}
	newsId.value = ""
}
function loadNewsList() {
	apiClient.newsFeed().then((result) => {
		newsList.value = result
		loading.value = false
	})
}
function callModal(newsId: string) {
	let days = 7
	modalOpened.value = true
	document.body.classList.add("modal-open")
	filteredList.value = new Array<News>()
	if (newsId) {
		newsId = newsId
		newsList.forEach((item) => {
			if (item.id == newsId) {
				filteredList.value.push(item)
			}
		})
	} else {
		newsId = ""
		apiClient.newsFeedAll(days).then((result) => {
			filteredList.value = result
			loading.value = false
		})
	}
}

function logPersonAccess(newsId: string) {
	let accessLog = new NewsPersonLog()
	accessLog.id = crypto.randomUUID()
	accessLog.newsId = newsId
	accessLog.personId = SessionInfo.currentUser().id
	accessLog.readTimeStamp = new Date()

	apiClient.postNewsPersonData(accessLog).then((response) => {
		loadNewsList()
	})
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable-table) {
	.p-datatable-tbody > tr > th {
		margin: top 1px bottom 1px !important;
	}

	.p-datatable-tbody > tr > td {
		text-align: left !important;
		font-size: 0.85rem !important;
		padding: 0.75rem !important;
	}

	.p-column-header-content {
		justify-content: left !important;
		font-size: 0.85rem !important;
	}
}

::v-deep(.p-paginator) {
	.p-paginator-current,
	.p-paginator-pages,
	.p-paginator-element,
	.p-dropdown-label,
	ul > li {
		font-size: 0.8rem !important;
	}

	.p-paginator {
		justify-content: flex-end !important;
	}
}

.modal-mask {
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	margin: 0px auto;
	padding: 10px 15px;
	background-color: rgb(233, 244, 255);
	border-radius: 4px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease-in-out;

	font-family: Helvetica, Arial, sans-serif;
}

.modal-container-zoom {
	margin: 0px auto;
	padding: 10px 15px;
	background-color: rgb(233, 244, 255);
	border-radius: 4px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease-in-out;
	width: fit-content;
	max-width: 60%;
	padding: 0.75em 0.75em;
	border-radius: 1.25em;
	box-shadow: 0.5em 0.5em 0.5em rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
	margin-top: 0;
	color: #145e3e;
	font-weight: bold;
}

.modal-body {
	margin: 10px 0;
}

.modal-default-button {
	float: right;
}
.zoomOverflow {
	overflow-y: auto !important;
	height: auto;
	max-height: 85vh;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.ex1 {
	background-color: rgba(211, 211, 211, 0.411);
	width: 100%;
	height: 400px;
	overflow-y: scroll;
	font-size: 1em;
	line-height: 1.2em;
}
.modal-open {
	overflow: hidden;
}
</style>
