<template>
	<div class="login">
		<div class="sign-in">
			<div class="logo">
				<img src="/logoWhite-stacked.svg" />
			</div>
			<Button type="button" :disabled="loggingIn" id="btnLogin" class="btn loginButton" @click="loginPopUp()">
				{{ loggingIn ? "Logging you in..." : "Log In" }}
			</Button>
			<div class="text-center text-white">v0.1</div>
			<div
				style="margin-top: 1em; padding: 0.5em 1em; display: flex; flex-direction: column; gap: 0.25rem; align-items: center; border-radius: 0.5rem"
				v-if="environmentSettings.environmentName != 'Production'"
				class="environmentClass"
			>
				<div>{{ environmentSettings.environmentName }}</div>
				<!-- <div id="pubDate">Published:October 7, 2024<br/>build 44512</div> -->
			</div>
			<div class="text-center text-white">
				<div id="branchInfo">Branch: HEAD</div>
				<div id="commitInfo">Commit: 40be78a</div>
				<div id="commitDate">2024-10-07 12:15:14 +0200</div>
			</div>
		</div>
		<div class="video-container"></div>
	</div>
</template>

<script setup lang="ts">
import { ref } from "vue"
//import { msalClient } from "../auth/azureAd"nnpm r

//import { Auth0Client } from "@auth0/auth0-spa-js"

//import { appAuth } from "../auth/appAuth"
import { useEnvironmentSettings } from "@/composables/environmentSettings"
import Button from "primevue/button"
import { appAuth } from "@/auth/appAuth"
import { useAuth0 } from "@auth0/auth0-vue"
import { useRouter } from "vue-router"
import { loginWithPopup } from "@/auth/auth0routeGuards"

const params = new URLSearchParams(window.location.search)

let errorMessage = ""
const loggingIn = ref(false)
const user = ref()
const environmentURL = import.meta.env.VITE_BASE_URL.toString()
const environmentSettings = useEnvironmentSettings()
const auth0 = useAuth0()
const router = useRouter()

const loginPopUp = async function () {
	loggingIn.value = true
	loginWithPopup(auth0).then(
		(loginSuccess) => {
			if (loginSuccess) {
				router.push("/app")
			} else {
				loggingIn.value = false
				console.log("failed")
			}
		},
		(error) => {
			loggingIn.value = false
			console.log(error)
		}
	)
}

// const loginWithMicrosoft = () => {
// 	//bypass login until app is registered by IT...
// 	// this.msal.login().then((loginOk) => {
// 	// 	if (loginOk) {
// 	// 		this.$router.push("/app")
// 	// 	} else {
// 	// 		this.errorMessage = "Login Failed"
// 	// 	}
// 	// })
// 	let authClient = auth.loginWithRedirect({ appState: { targetUrl: this.environmentURL } })
// 	//			this.$router.push("/app")
// }
</script>

<style scoped>
#pubDate {
	text-align: center;
}

.layoutWeb {
	display: grid;
	grid-template-columns: auto minmax(300px, 25%);
}

.layoutPhone {
	display: grid;
}

.background {
	background-image: url("@/assets/C_Center_White.svg");
	background-size: contain;
	background-repeat: no-repeat;
	min-height: 100vh;
	max-width: 100vw;
	position: relative;
	background-color: var(--primary);
	background-position-y: center;
	background-position-x: center;
}

.loginButton {
	padding: 1em 2em;
	margin: 1em;
	background: var(--button-primary);
	font-size: 18px;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	border-radius: 0.75rem;
	font-weight: 500;
	text-transform: uppercase;
	box-shadow: none;
	border: none;
}
Button.loginButton:hover {
	background: white;
	color: var(--button-primary);
}

body {
	background-color: var(--primary);
	padding: 0;
	margin: 0;
}
.login {
	display: flex;
	gap: 0em;
	flex-direction: row-reverse;
	align-items: center;
	align-content: stretch;
	padding: 0;
	margin: 0;
	background-color: var(--primary);
	height: 100vh;
}
.svg {
	position: absolute;
}
.video-container {
	overflow: hidden;
	align-items: center;
}
.video {
	clip-path: url(#swoosh);
	overflow: hidden;
	min-height: 100vh;
	width: 600px;
	float: right;
	background-color: var(--secondary);
}
video {
	align-content: center;
	height: 100vh;
	background-image: url(../assets/video-screen.jpg);
	max-width: inherit;
}
.sign-in {
	display: flex;
	flex: 50%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 2em;
	gap: 1em;
	height: 100%;
}
.logo img {
	margin: 0;
	width: 35rem;
	min-width: 25rem;
	max-width: 400px;
}
.gcdLogo {
	color: #ffffff;
	font-size: x-large;
	font-weight: 600;
	display: flex;
	flex-direction: row;
	gap: 0.75em;
	justify-content: center;
	align-items: center;
	max-width: 20rem;
}

.gcdLogo img {
	margin: 0;
	width: 7rem;
	max-width: 400px;
}

@media only screen and (max-width: 768px) {
	.login {
		gap: 0em;
	}
	.sign-in {
		margin: 1em;
	}
	.video {
		height: 100vh;
	}
}
@media only screen and (max-width: 600px) {
	video {
		display: none;
	}
	.sign-in {
		margin: 1em;
	}
	.logo img {
		width: 20em;
		min-width: 100px;
	}
	.gcdLogo {
		width: 10em;
		font-size: large;
	}
	.gcdLogo image {
		width: 5em;
		min-width: 50px;
	}
}
@media only screen and (max-height: 400px) {
	video {
		display: none;
	}
}

.environmentClass {
	background: v-bind("environmentSettings.getEnvironmentColors().backgroundColor");
	color: v-bind("environmentSettings.getEnvironmentColors().textColor");
	padding: 0 1em;
	font-size: medium;
}
</style>
