<template>
	<div class="flex flex-col gap-0.5 bg-white shadow sm:rounded-lg p-4 m-4">
		<div v-if="!displayForm">
			<div class="w-full flex justify-between align-middle">
				<PageLabel :helpPageName="'UserManagement'">User Management</PageLabel>
				<button
					class="btn btn-primary"
					id="btnNewUser"
					@click="newUser()"
					v-if="!displayForm && SessionInfo.hasRightsToScreen('Users', PageAccess.Create)"
				>
					New User
				</button>
			</div>
			<DataTable
				:value="persons"
				responsiveLayout="scroll"
				:row-hover="true"
				@row-click="rowClick($event)"
				:paginator="true"
				:rows="tablePaginator == null ? 10 : tablePaginator.rows"
				:first="tablePaginator == null ? 0 : tablePaginator.first"
				:page="tablePaginator == null ? 0 : tablePaginator.page"
				@page="pageChanged($event)"
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				:rowsPerPageOptions="[10, 20, 50]"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
				dataKey="id"
				v-model:filters="filters1"
				filterDisplay="menu"
				@filter="applyFilter($event)"
				:loading="loading1"
				:globalFilterFields="['emailid', 'firstName', 'userType.userTypeName']"
				id="dtUserList"
			>
				<template #header>
					<InputGroup>
						<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter1()" />
						<icSearchBox v-model="filters1['global'].value" />
					</InputGroup>
				</template>
				<template #empty> No customers found. </template>
				<template #loading> Loading user data. Please wait. </template>
				<Column field="emailid" header="Email" :sortable="true" style="flex: 0 0 30%">
					<template #body="{ data }">
						{{ data.emailid }}
					</template>
					<template #filter="{ filterModel }">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by email" />
					</template>
				</Column>
				<Column field="firstName" filterField="firstName" header="Name" :sortable="true" style="flex: 0 0 25%">
					<template #body="{ data }">
						<div class="w-full">{{ data.firstName + " " + data.lastName }}</div>
					</template>
					<template #filter="{ filterModel }">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name" />
					</template>
				</Column>
				<Column field="userType.userTypeName" filterField="userType.userTypeName" header="User Type" :sortable="true" style="flex: 0 0 25%">
					<template #body="{ data }">
						<div class="w-full">{{ data.userType.userTypeName }}</div>
					</template>
					<template #filter="{ filterModel }">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by user type" />
					</template>
				</Column>
				<Column field="lastLogin" filterField="lastLogin" header="Days since last login" :sortable="true" style="flex: 0 0 10%">
					<template #body="{ data }">
						<div class="w-full">{{ data.lastLogin != null ? calculatesDays(data.lastLogin) : "" }}</div>
					</template>
					<!-- <template #filter="{ filterModel }">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by user type" />
					</template> -->
				</Column>
				<Column field="projectCount" header="# of Projects" :sortable="true" style="flex: 0 0 10%">
					<template #body="{ data }">
						{{ getUserProjectCount(data.id) }}
					</template>
				</Column>
				<!-- <template #paginatorstart>
					<Button type="button" icon="pi pi-refresh" class="p-button-text" />
				</template>
				<template #paginatorend>
					<Button type="button" icon="pi pi-cloud" class="p-button-text" />
				</template> -->
			</DataTable>
		</div>
		<div v-if="displayForm">
			<userEdit :person="person" @cancel="cancelUserSave()" @refreshList="loaduserlist()" />
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { ApiClient, Person } from "@/../entities/NSwagGenerated"
import DataTable from "primevue/datatable"
import Column from "primevue/column"
import userEdit from "./userEdit.vue"
import "primeicons/primeicons.css"
import { PrimeIcons } from "primevue/api"
import { FilterMatchMode, FilterOperator } from "primevue/api"
import Button from "primevue/button"
import InputGroup from "primevue/inputgroup"
import InputText from "primevue/inputtext"
import { SessionInfo, PageAccess } from "@/auth/personSessionData"
import PageLabel from "@/components/pageLabel.vue"

export default defineComponent({
	name: "userList",
	components: {
		DataTable,
		Column,
		userEdit,
		Button,
		InputText,
		InputGroup,
		PageLabel,
	},
	data() {
		const apiClient = new ApiClient(import.meta.env.VITE_API_URL?.toString())
		const displayForm = false
		const persons = new Array<Person>()
		const person = new Person()
		let tablePaginator = null

		return { apiClient, displayForm, persons, person, filters1: null, loading1: true, SessionInfo, PageAccess, tablePaginator }
	},
	created() {
		this.initFilters1()
	},
	mounted() {
		this.loaduserlist()
		if (localStorage.getItem("tablePaginate")) {
			this.tablePaginator = JSON.parse(localStorage.getItem("tablePaginate"))
		}
		if (localStorage.getItem("tableFilter")) {
			this.filters1 = JSON.parse(localStorage.getItem("tableFilter"))
		}
	},
	methods: {
		calculatesDays(lastLoginDate) {
			const _MS_PER_DAY = 1000 * 60 * 60 * 24
			let dateNow = new Date(Date.now())
			let LoginDate = new Date(lastLoginDate)

			let days = this.dateDifference(dateNow, LoginDate)
			console.log("days", days)
			return days
		},
		dateDifference(date2, date1) {
			console.log(date2, date1)
			const _MS_PER_DAY = 1000 * 60 * 60 * 24

			// Discard the time and time-zone information.
			const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
			const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())

			return Math.floor((date2.getTime() - date1.getTime()) / _MS_PER_DAY)
		},
		applyFilter(ev) {
			localStorage.setItem("tableFilter", JSON.stringify(this.filters1))
		},
		pageChanged(ev) {
			this.tablePaginator = ev
			localStorage.setItem("tablePaginate", JSON.stringify(ev))
		},
		loaduserlist() {
			// return this.apiClient!.personAll().then((res) => {
			// 	console.log("user List", res)
			// 	this.persons = res
			// 	this.loading1 = false
			// })
			return this.apiClient!.selectedColumnUserList().then((res) => {
				//console.log("user List", res)
				this.persons = res
				//console.log("this.persons", this.persons)
				this.loading1 = false
			})
		},
		// loaduserlist() {
		// 	return this.apiClient!.personAll().then((res) => {
		// 		console.log("res", res)
		// 		if (res && res.length > 0) {
		// 			res.forEach((item) => {
		// 				if (item.projectPerson && item.projectPerson.length > 0) {
		// 					item.projectCount = item.projectPerson.length
		// 				} else {
		// 					item.projectCount = 0
		// 				}
		// 			})
		// 		}

		// 		this.persons = res
		// 		//console.log("this.persons", this.persons)
		// 		this.loading1 = false
		// 	})
		// },
		getUserProjectCount(userId: string) {
			//return this.persons.filter((p) => p.id == userId && p.projectPersonperson.project.projectStatus.projectStausName == "Closed").length
			let tempPersons = this.persons
			let person = tempPersons.filter((p) => p.id == userId)
			//console.log("projectPersonperson", projectPersonperson)
			let closeCount = 0

			if (person[0].projectPersonperson && person[0].projectPersonperson.length > 0) {
				person[0].projectPersonperson.forEach((item) => {
					if (item.project && item.project != null) {
						if (item.project.projectStatus && item.project.projectStatus != null) {
							if (item.project.projectStatus.projectStatusName == "Closed") {
								closeCount = closeCount + 1
							}
						}
					}
				})
			}

			//return 0
			return closeCount
		},
		cancelUserSave() {
			this.loaduserlist().then((res) => {
				this.displayForm = false
				this.person = new Person()
			})
		},
		rowClick(e) {
			this.apiClient.userGet(e.data.id).then(
				(res) => {
					this.displayForm = true
					this.person = res
				},
				(err) => {
					console.log("Error", err)
				}
			)
		},
		newUser() {
			this.person = new Person()
			this.displayForm = true
		},
		clearFilter1() {
			this.initFilters1()
		},
		initFilters1() {
			this.filters1 = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				emailid: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				firstName: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				"userType.userTypeName": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			}
		},
	},
	computed: {},
})
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
	.p-paginator-current {
		margin-left: auto;
	}
}

::v-deep(.p-progressbar) {
	height: 0.5rem;
	background-color: #d8dadc;

	.p-progressbar-value {
		background-color: #607d8b;
	}
}

::v-deep(.p-datepicker) {
	min-width: 25rem;

	td {
		font-weight: 400;
	}
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}
</style>
