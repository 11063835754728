import Vue from "vue"
import { createApp } from "vue"
import "./index.css"
import "./components/crispify/crispy.css"
import "./components/crispify/erm-brand.css"
import "./components/crispify/primevue-overrides.css"
import "./components/crispify/variables.css"
import "highlight.js/styles/default.min.css"
//import "./crispify/default.css/"
import router from "./components/router"
import PrimeVue from "primevue/config"
import ToastService from "primevue/toastservice"
import Tooltip from "primevue/tooltip"
import ConfirmationService from "primevue/confirmationservice"
import InputGroup from "primevue/inputgroup"

import { createPinia } from "pinia"
import App from "./App.vue"
import VueJsonPretty from "vue-json-pretty"
import { createAuth0 } from "@auth0/auth0-vue"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCircleLeft, faCircleInfo } from "@fortawesome/pro-regular-svg-icons"

import { faShare } from "@fortawesome/pro-solid-svg-icons"
library.add(faCircleLeft, faCircleInfo, faShare)

import DialogService from "primevue/dialogservice"

import { useEnvironmentSettings } from "./composables/environmentSettings"

import CKEditor from "@ckeditor/ckeditor5-vue"

async function init() {
	if (!sessionStorage.getItem("targetRoute")) {
		sessionStorage.setItem("targetRoute", "/app")
	}

	const app = createApp(App)
	const pinia = createPinia()

	app
		.use(pinia)
		.use(ConfirmationService)
		.use(router)
		.use(PrimeVue, { ripple: true })
		.use(ToastService)
		.use(DialogService)
		.use(CKEditor)
		//.use(autoAnimatePlugin)
		//.use(Auth0Created)
		.use(
			createAuth0({
				clientId: useEnvironmentSettings().auth0ClientKey,
				domain: useEnvironmentSettings().auth0Domain,
				//audience: import.meta.env.VITE_AUTH0_AUDIENCE,
				//redirectUri: window.location.origin,
				authorizationParams: {
					redirect_uri: window.location.origin,
					audience: useEnvironmentSettings().auth0Audience,
				},
			})
		)
		.directive("tooltip", Tooltip)
		.component("VueJsonPretty", VueJsonPretty)
		.component("FontAwesomeIcon", FontAwesomeIcon)
		.component("InputGroup", InputGroup)

		.mount("#app")
}

init()
