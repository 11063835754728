<template><Toast /></template>

<script setup lang="ts">
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { useEnvironmentSettings } from "@/composables/environmentSettings"
import { useToast } from "primevue/usetoast"
import { ref } from "vue"
import VueJsonPretty from "vue-json-pretty"
import Toast from "primevue/toast"
import { EventBus } from "@/eventBus"
import { AiChatMessages, PersonSessionData } from "@/../entities/NSwagGenerated"
import { SessionInfo } from "@/auth/personSessionData"

//https://learn.microsoft.com/en-us/javascript/api/%40microsoft/signalr/?view=signalr-js-latest&viewFallbackFrom=aspnetcore-7.0

const realtimeMessaging = ref(
	new HubConnectionBuilder()
		.withUrl(useEnvironmentSettings().apiURL + "/realtimemessaging")
		.configureLogging(LogLevel.Information)
		.build()
)

const toast = useToast()
const lastMessage = ref("")
const lastUser = ref("")

async function start() {
	if (realtimeMessaging.value.state == "Connected") {
		//realtimeMessaging.value.invoke("logUserConnection", SessionInfo.currentUser().id, realtimeMessaging.value.connectionId)
		return
	}

	try {
		await realtimeMessaging.value.start()
		// toast.add({ severity: "success", summary: "SignalR Connected", life: 3000 })
		realtimeMessaging.value.invoke("logUserConnection", SessionInfo.currentUser().id, realtimeMessaging.value.connectionId)
	} catch (err) {
		console.log(err)
		setTimeout(start, 5000)
	}
}

realtimeMessaging.value.onclose(async () => {
	await start()
})

realtimeMessaging.value.on("chatResponse", (user, message) => {
	// toast.add({ severity: "info", summary: user, detail: message })
	EventBus.emit("chatResponse", message)
})

// Start the connection.
start()
</script>

<style scoped></style>
