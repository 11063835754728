<template>
	<div class="form-row" :class="{ split: split, right: right, left: left }">
		<slot />
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({
	name: "icFormRow",
	components: {},
	props: {
		split: {
			type: Boolean,
		},
		right: {
			type: Boolean,
		},
		left: {
			type: Boolean,
		},
	},
})
</script>

<style scoped></style>
