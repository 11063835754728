import { ref } from "vue"
import { SessionInfo, PageAccess } from "@/auth/personSessionData"

export function useFormSecurity() {
	function getLoggedInUser(): SessionInfo {
		return JSON.parse(localStorage.getItem("sessionInfo") || "{}")
	}

	function applyPageAccessSecurity(screenName: string) {
		if (!SessionInfo.hasRightsToScreen(screenName, PageAccess.Update)) {
			var form = document.getElementById(screenName)
			if (form) {
				//the container exists with the same name as the screen
				setTimeout(() => {
					//browse through all inputs
					var inputs = form.getElementsByTagName("input")
					for (var i = 0; i < inputs.length; i++) {
						inputs[i].disabled = true
						inputs[i].classList.add("text-gray-400")
					}

					//browse through all other selectors like dropdown, checkbox, radio
					var selectControls = form.querySelectorAll(".p-dropdown,.p-field-checkbox,.p-radiobutton,.p-multiselect,.p-inputtextarea,.p-autocomplete")

					for (var i = 0; i < selectControls.length; i++) {
						selectControls[i].classList.add("p-disabled")
					}

					//browse through buttons
					var buttons = form.getElementsByClassName("btn-primary")
					for (var i = 0; i < buttons.length; i++) {
						if (buttons[i].textContent.indexOf("BACK TO LIST") == -1) buttons[i].classList.add("p-hidden")
					}
				}, 3000)
			} else {
				//return error
				alert("Security container not found for " + screenName)
			}
		}
	}
	return { applyPageAccessSecurity, getLoggedInUser }
}
