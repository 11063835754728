import { ref } from "vue"
import { SessionInfo } from "./personSessionData"
import { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js"
import { appAuth } from "../auth/appAuth"
export abstract class routeGuards {
	public static requiresAdmin(to, from, next) {
		if (SessionInfo.currentUserIsAdmin()) {
			next()
		} else {
			next("/")
		}
	}
	/*
	public static requireLoggedAuth(to, from, next){
		console.log("currentUser",SessionInfo.currentUser())

		//var ciao = Auth0Plugin()
		console.log("ciao",auth0)
		if (SessionInfo.currentUser()) {
			next()
		} else {
			auth0.loginWithPopup().then(()=>{
				if (auth0.isAuthenticated.value) {
					auth0.getAccessTokenSilently().then((token) => {
						sessionStorage.setItem("token", token)
						appAuth.loginToAppWithToken(token).then((callback)=>{
							if (callback!="ok"){
								sessionStorage.setItem("error", callback)
							}
							router.push("/app/")				
						})
					})
				}	
			})			
			next("/")
		}
	}
*/
	public static requiresLogin(to, from, next) {
		//console.log("currentUser", SessionInfo.currentUser())
		if (SessionInfo.currentUser()) {
			next()
		} else {
			const auth0Options: Auth0ClientOptions = {
				client_id: import.meta.env.VITE_AUTH0_CLIENT_KEY,
				domain: import.meta.env.VITE_AUTH0_DOMAIN,
				audience: import.meta.env.VITE_AUTH0_AUDIENCE,
				redirectUri: window.location.origin,
			}
			const auth0 = new Auth0Client(auth0Options)
			auth0.loginWithPopup().then(async () => {
				//user.value = auth0.user
				//if (auth0.isAuthenticated.value) {

				const token = await auth0.getTokenSilently()

				if (token) {
					sessionStorage.setItem("token", token)

					await appAuth.loginToAppWithToken(token).then((callback) => {
						if (callback != "ok") {
							sessionStorage.setItem("error", callback)
							return false
							//logout()
						} else {
							return next()
						}
					})
					return next()
				} else {
					return false
				}
			})
		}
	}
}
