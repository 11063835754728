<template>
	<div class="flex flex-col gap-0.5 bg-white shadow sm:rounded-lg p-4 m-4">
		<div v-if="!displayForm">
			<div class="flex justify-between w-full mb-4">
				<PageLabel :helpPageName="'News'">News Management</PageLabel>
				<button id="btnCreateNews" class="btn btn-primary" @click="newNews()">New Article</button>
			</div>
			<DataTable
				:value="newsList"
				responsiveLayout="scroll"
				:row-hover="true"
				@row-click="rowClick($event)"
				:paginator="true"
				:rows="10"
				paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
				:rowsPerPageOptions="[10, 20, 50]"
				currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
				dataKey="id"
				v-model:filters="filters1"
				filterDisplay="menu"
				:loading="loading1"
				:globalFilterFields="['headline']"
				id="dtNewsList"
			>
				<template #header>
					<InputGroup>
						<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter1()" />
						<icSearchBox v-model="filters1['global'].value" />
					</InputGroup>
				</template>
				<template #empty> No Articles found. </template>
				<template #loading> Loading News. Please wait. </template>
				<Column field="headline" header="Headline" :sortable="true">
					<template #body="{ data }">
						{{ data.headline }}
					</template>
					<!-- <template #filter="{ filterModel }">
						<InputText id="newsSearch" type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by headline" />
					</template> -->
				</Column>
				<Column field="category" header="Category" :sortable="true">
					<template #body="{ data }">
						<div class="flex gap-1 justify-left">
							<component :is="data.category.categoryIcon" class="mt-1" aria-hidden="true" style="width: 16px; height: 16px"></component>
							<span class="mt-0">{{ data.category.categoryName }}</span>
						</div>
					</template>
					<!-- <template #filter="{ filterModel }">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Category" />
					</template> -->
				</Column>
				<Column field="createdDate" header="Created Date" :sortable="true">
					<template #body="{ data }">
						{{ formatDate(data.createdDate) }}
					</template>
					<!-- <template #filter="{ filterModel }">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by sector" />
					</template> -->
				</Column>
				<Column field="releaseDate" header="Release Date" :sortable="true">
					<template #body="{ data }">
						{{ formatDate(data.releaseDate) }}
					</template>
					<!-- <template #filter="{ filterModel }">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by asset" />
					</template> -->
				</Column>
				<Column field="expirationDate" header="Expiration Date" :sortable="true">
					<template #body="{ data }">
						{{ formatDate(data.expirationDate) }}
					</template>
					<!-- <template #filter="{ filterModel }">
						<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by asset" />
					</template> -->
				</Column>
			</DataTable>
		</div>
		<div v-if="displayForm">
			<newsForm
				:newsDetails="news"
				@refreshList="loadNewsList()"
				@cancel="cancelNewsSave()"
				@updateNewsDetails="updateNewsDetails"
				v-if="renderForm"
			></newsForm>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { ApiClient, News } from "@/../entities/NSwagGenerated"

import DataTable from "primevue/datatable"
import Column from "primevue/column"
import { axiosClient } from "@/comms/apiClient"
import axios from "axios"
import { SessionInfo, PageAccess } from "@/auth/personSessionData"
import "primeicons/primeicons.css"
import newsForm from "./newsForm.vue"

import { FilterMatchMode, FilterOperator } from "primevue/api"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import InputGroup from "primevue/inputgroup"

import PageLabel from "@/components/pageLabel.vue"
import { AdjustmentsHorizontalIcon, AcademicCapIcon, SpeakerWaveIcon, ChartPieIcon, ExclamationCircleIcon } from "@heroicons/vue/24/outline"

export default defineComponent({
	name: "newsList",
	data() {
		const apiClient = new ApiClient(import.meta.env.VITE_API_URL?.toString())

		const newsList = new Array<object>()
		const displayForm: boolean = false
		const news = new News()
		let newsId: string = ""
		let renderForm = true

		return {
			apiClient,
			displayForm,
			SessionInfo,
			PageAccess,
			filters1: null,
			loading1: true,
			renderForm,
			news,
			newsList,
			newsId,
			InputGroup,
		}
	},

	components: {
		DataTable,
		Column,
		Button,
		InputText,
		PageLabel,
		newsForm,
		AdjustmentsHorizontalIcon,
		AcademicCapIcon,
		SpeakerWaveIcon,
		ChartPieIcon,
		ExclamationCircleIcon,
	},
	mounted() {
		this.loadNewsList()
	},
	created() {
		this.initFilters1()
	},

	methods: {
		updateNewsDetails(nd) {
			this.news = nd
			this.renderForm = false
			this.$nextTick(() => {
				this.renderForm = true
			})
		},
		formatDate(dateValue) {
			return new Date(dateValue.toString()).toLocaleDateString("en-US")
		},
		loadNewsList() {
			this.apiClient.newsAll().then((result) => {
				this.newsList = result
				this.loading1 = false
			})
		},

		cancelNewsSave() {
			this.displayForm = false
			this.loadNewsList()
			console.log("cancel save", this.displayForm)
		},
		rowClick(e) {
			this.apiClient!.newsGet(e.data.id).then(
				(result) => {
					this.news = result
					this.displayForm = true
					this.newsId = this.news.id
				},
				(err) => {
					console.log(err)
				}
			)
		},

		newNews() {
			this.news = new News()
			this.displayForm = true
		},
		clearFilter1() {
			this.initFilters1()
		},
		initFilters1() {
			this.filters1 = {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
				headline: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
			}
		},
	},
})
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
	.p-paginator-current {
		margin-left: auto;
	}
}

::v-deep(.p-progressbar) {
	height: 0.5rem;
	background-color: #d8dadc;

	.p-progressbar-value {
		background-color: #607d8b;
	}
}

::v-deep(.p-datepicker) {
	min-width: 25rem;

	td {
		font-weight: 400;
	}
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}
</style>
