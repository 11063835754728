<template>
	<div class="flex flex-row min-h-screen" id="top">
		<SidebarMenu> </SidebarMenu>
		<div class="max-w-full mx-auto bg-gray-100" style="min-width: calc(100% - 300px); width: 100%" id="main-content">
			<div class="flex flex-col min-h-full align-middle">
				<div class="flex flex-row justify-between px-4 text-3xl text-white bg-secondary items-center">
					<span class="environmentBackground">{{ environmentSettings.environmentName }}</span>

					<span class="flex items-center">
						<div class="flex flex-row gap-2 text-xl justify-items-center" id="loading" v-if="apiCount > 0">
							Loading<ProgressBar style="display: flex; width: 70px" mode="indeterminate" />
						</div>
						<div><img src="/logoWhite.svg" style="height: 2rem; max-height: 65px" /> <RealTimeMessaging /><socketIO /></div>
					</span>
				</div>
				<main role="main">
					<router-view :key="$route.fullPath" name="maincontent"></router-view>
					<Toast ref="toast" position="top-right" />

					<DynamicDialog />
				</main>
			</div>
		</div>
	</div>
	<Sidebar
		position="right"
		style="width: 40em; max-width: 40vw"
		v-model:visible="showHelp"
		@hide="closeHelp"
		:showCloseIcon="false"
		:baseZIndex="1000"
	>
		<div class="flex flex-col gap-4 mb-4">
			<div class="flex flex-row-reverse justify-between">
				<button aria-label="Close Help" @click="showHelp = false">
					<FontAwesomeIcon class="text-redSignal largeheader" :icon="['far', 'circle-xmark']" />
				</button>
				<h1>{{ userFriendlyName }}</h1>
			</div>
		</div>
		<div v-html="helpContent" />
	</Sidebar>
	<Dialog
		:header="dialogContent.header"
		class="i2Dialog"
		:modal="dialogContent.isModal"
		:position="dialogContent.position || 'top'"
		v-model:visible="showDialog"
		style="min-width: 33vw"
	>
		{{ dialogContent.content }}
	</Dialog>

	<!--GS start-->
	<Dialog header="Log in" class="i2Dialog" position="top" v-model:visible="showLoginDialog" style="min-width: 33vw"> fgfg </Dialog>
	<!--GS end-->

	<ConfirmDialog></ConfirmDialog>
</template>

<script lang="ts">
import { defineComponent, inject, watch } from "vue"
import ConfirmDialog from "primevue/confirmdialog"
import DynamicDialog from "primevue/dynamicdialog"
import SidebarMenu from "./sidebarMenu.vue"
import { EventBus } from "@/eventBus"
import ProgressBar from "primevue/progressbar"
import Sidebar from "primevue/sidebar"
import { ApiClient, HelpPage } from "@/../entities/NSwagGenerated"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCircleXmark } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { useEnvironmentSettings } from "@/composables/environmentSettings"
import { SystemToastOptions } from "@/composables/toaster"
import mitt from "mitt"

library.add(faCircleXmark)
import Dialog from "primevue/dialog"
import DialogContent from "@/models"
import Toast, { ToastMessageOptions } from "primevue/toast"
import { useToast } from "primevue/usetoast"

export default defineComponent({
	name: "frame",
	components: { Toast, SidebarMenu, ProgressBar, Sidebar, FontAwesomeIcon, Dialog, ConfirmDialog, DynamicDialog },
	data() {
		//start login
		let errorMessage = ""
		let loggingIn = false
		const environmentURL = import.meta.env.VITE_BASE_URL.toString()

		let apiCount = 0
		let showHelp = false
		let helpContent = ""
		let userFriendlyName = ""
		let helpPageName = ""
		let dialogContent = new DialogContent()
		let showDialog = false
		let showLoginDialog = false
		const environmentSettings = useEnvironmentSettings()
		const toast = useToast()

		const apiClient = new ApiClient(environmentSettings.apiURL)
		return {
			apiCount,
			showHelp,
			helpContent,
			apiClient,
			userFriendlyName,
			helpPageName,
			dialogContent,
			showDialog,
			showLoginDialog,
			environmentSettings,
			//login start
			errorMessage,
			loggingIn,
			environmentURL,

			//login end
			toast,
		}
	},
	mounted() {
		EventBus.on("IncrementApiCalls", () => {
			this.apiCount++
		})

		watch(
			() => this.apiCount,
			(newValue, oldValue) => {
				if (newValue == 0) {
					EventBus.emit("HideLoading")
				} else {
					EventBus.emit("ShowLoading")
				}
			}
		)

		EventBus.on("systemToast", (options: any) => {
			this.toast.add(options)
		})

		EventBus.on("ShowDialog", (dialogContent) => {
			this.dialogContent = JSON.parse(JSON.stringify(dialogContent))
			this.showDialog = true
		})

		EventBus.on("ShowLoginDialog", (dialogContent) => {})

		EventBus.on("DecrementApiCalls", () => {
			if (this.apiCount > 0) {
				this.apiCount--
			}
		})

		EventBus.on("APIError", (err: any) => {
			if (err.status == 204) {
				this.helpContent = `No help configuration for Help Page Name "${this.helpPageName}"`
			} else {
				this.helpContent = err.message
			}
		})

		EventBus.on("OpenPageHelp", (helpPageData: any) => {
			this.userFriendlyName = helpPageData.userFriendlyName
			this.helpPageName = helpPageData.helpPageName
			this.apiClient
				.getHelpPageByName(helpPageData.helpPageName)
				.then(
					(res) => {
						if (res) {
							this.helpContent = res.helpContent || "No Help Found"
						} else {
							this.helpContent = `No help configuration for Help Page Name "${this.helpPageName}"`
						}
					},
					(err) => {
						console.log(err)
					}
				)
				.finally(() => {
					this.showHelp = true
				})
		})
	},
	methods: {
		closeHelp() {},
	},
})
</script>

<style scoped>
::v-deep(.p-dialog-title) {
	background: red !important;
	color: white !important;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.skip-to-content-link {
	background: var(--primary);
	color: white;
	height: 36px;
	left: 50%;
	padding: 10px;
	position: absolute;
	transform: translateY(-100%);
	transition: transform 0.3s;
}

.skip-to-content-link:focus {
	transform: translateY(0%);
}

.environmentBackground {
	background: v-bind("environmentSettings.getEnvironmentColors().backgroundColor");
	color: v-bind("environmentSettings.getEnvironmentColors().textColor");
	padding: 1em 1em;
	font-size: medium;
}
</style>
