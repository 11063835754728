import { SessionInfo } from "./personSessionData"
import { Auth0VueClient, useAuth0 } from "@auth0/auth0-vue" //Auth0PluginOptions
import { useRouter } from "vue-router"
import { appAuth } from "../auth/appAuth"

import {
	createAuth0Client,
	Auth0Client,
	GetTokenSilentlyOptions,
	GetTokenWithPopupOptions,
	LogoutOptions,
	RedirectLoginOptions,
	User,
} from "@auth0/auth0-spa-js"

interface Auth0PluginOptions {
	domain: string
	clientId: string
	audience: string
	redirectUri: string

	onRedirectCallback(appState: any): void
}
let options = {} as Auth0PluginOptions

const client = await createAuth0Client({
	// domain: process.env.VUE_APP_AUTH0_DOMAIN,
	// client_id: process.env.VUE_APP_AUTH0_CLIENT_KEY,
	domain: options.domain,
	client_id: options.clientId,
	audience: options.audience,
	redirect_uri: options.redirectUri,
	cacheLocation: "localstorage",
})

export const loginWithPopup = async function (auth0: Auth0VueClient): Promise<Boolean> {
	let loginResult = await auth0.loginWithPopup().then(async () => {
		//user.value = auth0.user
		//if (auth0.isAuthenticated.value) {

		const token = await auth0.getAccessTokenSilently()

		if (token) {
			sessionStorage.setItem("token", token)

			let result = await appAuth.loginToAppWithToken(token).then((callback) => {
				if (callback != "ok") {
					sessionStorage.setItem("error", callback)
					return false
					//logout()
				} else {
					return true
				}
			})
			return result
		} else {
			return false
		}
	})
	return loginResult
}

// export const checkAuthLogin = async function () {
// 	auth0.getAccessTokenSilently().then((token) => {
// 		console.log(token)
// 	})
// }
