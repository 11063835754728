<template>
	<div class="flex flex-col gap-10">
		<form @submit.prevent="save()" id="Users" novalidate>
			<div class="w-full justify-between mb-4 flex gap-2">
				<PageLabel :helpPageName="'UserManagement_Detail'">User Management</PageLabel>

				<span class="flex gap-2">
					<button type="button" @click="$emit('cancel')" id="btnBackToUserList" class="btn bg-redSignal">Back to List</button>
					<button class="btn btn-primary" id="btnSaveUser" v-if="SessionInfo.hasRightsToScreen('Users', PageAccess.Update)">Save</button>
				</span>
			</div>

			<IcFormRow>
				<IcFormField label="First Name">
					<InputText
						type="text"
						id="firstName"
						ref="firstName"
						v-model="person.firstName"
						:class="{ invalid: saveAttempted && !person.firstName }"
						class="w-full"
					/>
				</IcFormField>
				<IcFormField label="Last Name">
					<InputText
						type="text"
						id="lastName"
						ref="lastName"
						v-model="person.lastName"
						:class="{ invalid: saveAttempted && !person.lastName }"
						class="w-full"
					/>
				</IcFormField>
			</IcFormRow>
			<IcFormRow>
				<IcFormField label="Email">
					<InputText
						type="email"
						id="email"
						ref="emailAddress"
						v-model="person.emailid"
						:class="{ invalid: saveAttempted && !person.emailid }"
						class="w-full"
					/>
				</IcFormField>
				<IcFormField label="User Type">
					<Dropdown
						id="userType"
						placeholder="Select..."
						:class="{ 'p-invalid': saveAttempted && !person.userTypeId }"
						v-model="person.userTypeId"
						:options="userType"
						option-value="id"
						option-label="userTypeName"
						@change="getSelection($event)"
					>
					</Dropdown>
				</IcFormField>
			</IcFormRow>
			<IcFormRow>
				<IcFormField label="Employer">
					<Dropdown
						id="personType"
						placeholder="Select..."
						:class="{ 'p-invalid': saveAttempted && !person.personTypeId }"
						v-model="person.personTypeId"
						:options="persontypearray"
						option-value="id"
						option-label="personTypeName"
					>
					</Dropdown>
				</IcFormField>
				<IcFormField label="Client Access" v-if="false">
					<MultiSelect
						ref="selectClient"
						v-model="selectClient"
						:options="clientlist"
						optionLabel="clientName"
						placeholder="Select client name"
						display="chip"
						style="width: 100%"
						:show-toggle-all="false"
						:selected-items-label="'{0} items'"
						@change="changeClientSelection($event)"
						id="client"
					/>
				</IcFormField>
			</IcFormRow>

			<Toast id="UserToast" position="top-right" />
		</form>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { ApiClient, Client, Person, PersonType, UserClient, UserType } from "@/../entities/NSwagGenerated"
import { v4 as uuidv4 } from "uuid"
import MultiSelect from "primevue/multiselect"
import Toast from "primevue/toast"
import Dropdown from "primevue/dropdown"
import InputText from "primevue/inputtext"
import { SessionInfo, PageAccess } from "@/auth/personSessionData"
import { useFormSecurity } from "@/composables/formSecurity"
import PageLabel from "@/components/pageLabel.vue"

export default defineComponent({
	name: "userEdit",
	components: {
		Dropdown,
		Toast,
		InputText,
		MultiSelect,
		PageLabel,
	},
	data() {
		const apiClient = new ApiClient(import.meta.env.VITE_API_URL?.toString())
		let saveAttempted = false
		const persontypearray = new Array<PersonType>()
		const userType = new Array<UserType>()
		const clientlist = new Array<Client>()
		const selectClient = new Array<any>()
		const lstuserclient = new Array<UserClient>()
		const selectUserType = ""
		return {
			apiClient,
			persontypearray,
			userType,
			saveAttempted,
			clientlist,
			selectClient,
			lstuserclient,
			selectUserType,
			SessionInfo,
			PageAccess,
		}
	},
	emits: ["cancel", "refreshList"],
	props: {
		person: {
			type: Person,
			required: true,
		},
	},
	mounted() {
		this.apiClient.personTypeAll().then((res) => (this.persontypearray = res))
		this.apiClient.userTypeAll().then((res) => (this.userType = res))

		this.apiClient.clientAll().then((res) => {
			this.clientlist = res

			if (this.person.id) {
				this.apiClient.getclientNamebyUser(this.person.id).then((res) => {
					res.forEach((value, index) => {
						let clientFind = this.clientlist.find((a) => a.id == value.clientId)
						this.selectClient.push(clientFind)
					})
				})
			}
		})

		useFormSecurity().applyPageAccessSecurity("Users")
	},
	methods: {
		cancel() {},
		validateRequired(obj: any, defaultFields: Array<string>) {
			let noMissingFields = true
			let message = "Highlighted fields are required"
			defaultFields.forEach((field) => {
				if (!obj[field] || obj[field]?.length == 0) {
					noMissingFields = false
				}
				// if (field == "emailid" && noMissingFields) {
				// 	let address = this.$refs["emailAddress"].value
				// 	let validation = new RegExp(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)
				// 	if (!validation.test(address)) {
				// 		noMissingFields = false
				// 		message = "Invalid Email Id"
				// 	}
				// }
			})

			if (!noMissingFields) {
				this.$toast.add({ severity: "error", summary: message, life: 2000 })
			}

			return noMissingFields
		},
		save() {
			this.saveAttempted = true
			if (this.person.id && this.selectUserType == "") {
				this.getUserType(this.person.userTypeId)
			}

			if (!this.validateRequired(this.person, ["firstName", "lastName", "emailid", "userTypeId"])) {
				return
			}

			if (!this.person.id || this.person.id == "NEW") {
				//if nulll, create new
				this.person.id = uuidv4()
				if (this.selectClient.length > 0) {
					this.selectClient.forEach((value, index) => {
						let uc = new UserClient()
						uc.id = uuidv4()
						uc.personId = this.person.id
						uc.clientId = value.id
						this.lstuserclient.push(uc)
					})
					this.person.userClientperson = this.lstuserclient
				}

				this.apiClient.userPost(this.person).then(
					(res) => {
						this.$toast.add({ severity: "success", summary: "Saved", life: 3000 })
					},
					(err) => {
						if (err.status != 409) {
							this.$toast.add({ severity: "error", summary: "Error Saving", detail: "Tell Support:" + err.message })
						} else {
							this.$toast.add({ severity: "error", summary: "Error Saving", detail: "A user with this email already exists" })
							this.person.id = "NEW"
						}
					}
				)
			} else {
				this.lstuserclient.splice(0)
				this.selectClient.forEach((value, index) => {
					let uc = new UserClient()
					uc.id = uuidv4()
					uc.personId = this.person.id
					uc.clientId = value.id
					this.lstuserclient.push(uc)
				})

				this.person.userClientperson = this.lstuserclient

				this.apiClient
					.userPut(this.person.id, this.person)
					.then(
						(res) => {
							this.$toast.add({ severity: "success", summary: "Saved", life: 3000 })
						},
						(err) => {
							if (err.status != 409) {
								this.$toast.add({ severity: "error", summary: "Error Saving", detail: "Tell Support:" + err.message })
							} else {
								this.$toast.add({ severity: "error", summary: "Error Saving", detail: "A user with this email already exists" })
							}
						}
					)
					.catch((error) => {
						console.log("Custom Error:", error.response.data)
					})
			}
			this.$emit("refreshList")
		},
		getSelection(event) {
			this.getUserType(event.value)
		},
		changeClientSelection(event) {
			this.selectClient = event.value
		},
		getUserType(typeId) {
			this.userType.forEach((value, index) => {
				if (value.id == typeId) {
					this.selectUserType = value.userTypeName
					return
				}
			})
		},
	},
	computed: {},
})
</script>

<style>
.invalid {
	border-color: red;
}

.p-card {
	background: blue;
}

.p-card-header {
	background: var(--primary);
	color: white;
	font-weight: bold;
	padding: 0.5rem 1rem;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
</style>
