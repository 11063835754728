import { Socket, io } from "socket.io-client"
import { useEnvironmentSettings } from "./environmentSettings"
import { EventBus } from "../eventBus"
import { useRealtimeCommunicationStore, ConnectionStatus } from "../store/realtimeCommunicationStore"

// let retryUntilTokenIsAvailable = setInterval(() => {
// 	if (sessionStorage.getItem("token")) {
// 		clearInterval(retryUntilTokenIsAvailable)
// 	}
// 	const socket = io(useEnvironmentSettings().aiURL, {
// 		path: "/sockets",
// 		auth: {
// 			token: sessionStorage.getItem("token"),
// 		},
// 	}
// 	)},1000)

let systemSocket: Socket | undefined = undefined

const useSocket = () => {
	if (!systemSocket) {
		systemSocket = io(useEnvironmentSettings().aiURL, {
			path: "/sockets",
			port: useEnvironmentSettings().SOCKET_IO_PORT,
			auth: {
				token: sessionStorage.getItem("token"),
			},
		})
	}
	return systemSocket
}

export { useSocket }
