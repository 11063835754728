import { ref, onMounted, onUnmounted } from "vue"

export type EnvironmentColors = {
	backgroundColor: string
	textColor: string
	environmentName: string
}

// by convention, composable function names start with "use"
export function useEnvironmentSettings() {
	const environmentName = import.meta.env.VITE_ENVIRONMENT_NAME.toString()
	const baseURL = import.meta.env.VITE_BASE_URL.toString()
	const apiURL = import.meta.env.VITE_API_URL.toString()
	const aiURL = import.meta.env.VITE_AI_URL.toString()
	const auth0ClientKey = import.meta.env.VITE_AUTH0_CLIENT_KEY.toString()
	const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN.toString()
	const auth0Audience = import.meta.env.VITE_AUTH0_AUDIENCE.toString()
	const SOCKET_IO_PORT = import.meta.env.VITE_SOCKET_IO_PORT.toString()

	function getEnvironmentColors(): EnvironmentColors {
		switch (environmentName) {
			case "Development":
				return { backgroundColor: "#237f52", textColor: "White", environmentName } //green
			case "UAT":
				return { backgroundColor: "Yellow", textColor: "black", environmentName } //orange
			case "Demo":
				return { backgroundColor: "white", textColor: "rgb(0, 51, 98)", environmentName } //dark blue
			case "Training":
				return { backgroundColor: "darkviolet", textColor: "white", environmentName } //violet
			case "CIP Support":
				return { backgroundColor: "#AA4A44", textColor: "white", environmentName } //redbrick
			case "digital-crisp-prod-staging":
				return { backgroundColor: "orange", textColor: "rgba(0, 0, 0, 0)", environmentName } //invisible
			case "Production":
				return { backgroundColor: "rgba(0, 0, 0, 0)", textColor: "rgba(0, 0, 0, 0)", environmentName } //invisible
			default:
				return { backgroundColor: "rgb(113, 113, 113)", textColor: "white", environmentName: environmentName + " is not a valid environment name" } //grey
		}
	}

	return { getEnvironmentColors, baseURL, apiURL, environmentName, aiURL, auth0ClientKey, auth0Domain, auth0Audience, SOCKET_IO_PORT }
}
