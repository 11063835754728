<template>
	<div class="flex flex-col" id="formContainer">
		<form @submit.prevent="save()">
			<div class="flex justify-between w-full gap-2 mb-4">
				<PageLabel :helpPageName="'NewsDetails'">Article</PageLabel>
				<span class="flex gap-2">
					<button id="btnBackToNewsList" type="button" data-cy="buttonNewsBack" @click="$emit('cancel')" class="btn bg-redSignal">
						Back to List
					</button>
					<button id="btnNewsSave" class="btn btn-primary" data-cy="buttonNewsSave">Save Article</button>
				</span>
			</div>
			<div class="grid gap-2 lg:grid-cols-2">
				<div style="grid-column-start: 1; grid-column-end: 2; padding: 5px">
					<label for="headline" class="formLabel">Headline</label>
					<input
						type="text"
						v-model="newsDetails.headline"
						data-cy="headline"
						:class="{ invalid: saveAttempted && !newsDetails.headline }"
						class="formText"
						maxlength="200"
						id="headline"
					/>
				</div>
				<div style="grid-column-start: 2; grid-column-end: -1; padding: 5px">
					<label for="linkURL" class="formLabel">Link URL</label>
					<input type="text" v-model="newsDetails.linkURL" data-cy="linkURL" class="formText" maxlength="200" id="linkURL" />
				</div>
				<div style="grid-column-start: 1; grid-column-end: 2; padding: 5px">
					<label for="releaseDate" class="formLabel">Release Date</label>
					<Calendar
						id="releaseDate"
						class="formText"
						v-model="releaseDate"
						:showIcon="true"
						:class="{ invalid: saveAttempted && !this.releaseDate }"
						placeholder="mm/dd/yyyy"
						readonlyInput="true"
						:showTime="true"
						:showSeconds="true"
					/>
				</div>
				<div style="grid-column-start: 2; grid-column-end: -1; padding: 5px">
					<label for="expirationDate" class="formLabel">Expiration Date</label>
					<Calendar
						id="expirationDate"
						class="formText"
						v-model="expirationDate"
						:showIcon="true"
						:class="{ invalid: saveAttempted && !this.expirationDate }"
						placeholder="mm/dd/yyyy"
						readonlyInput="true"
						:showTime="true"
						:showSeconds="true"
					/>
				</div>
				<div style="grid-column-start: 1; grid-column-end: 2; padding: 5px">
					<label for="category" class="formLabel">Category</label>
					<Dropdown
						id="category"
						data-cy="category"
						placeholder="Select Category..."
						v-model="categoryId"
						:class="{ invalid: saveAttempted && !categoryId }"
						:options="catgeoryList"
						option-value="id"
						option-label="categoryName"
						@change="setValue($event.value)"
					>
						<template #value="slotProps">
							<div class="flex gap-1 mb-1 justify-left" v-if="selectedCategory.id">
								<component :is="selectedCategory.categoryIcon" class="mt-1" aria-hidden="true" style="width: 16px; height: 16px"></component>
								<span>{{ selectedCategory.categoryName }}</span>
								<!-- <component
									:is="catgeoryList.find((c) => c.id == slotProps.value).categoryIcon"
									class="mt-1"
									aria-hidden="true"
									style="width: 16px; height: 16px"
								></component>
								<span>{{ catgeoryList.find((c) => c.id == slotProps.value).categoryName }}</span>-->
							</div>
							<span v-else>
								{{ slotProps.placeholder }}
							</span>
						</template>
						<template #option="slotProps">
							<div class="flex gap-1 mb-1 justify-left">
								<component :is="slotProps.option.categoryIcon" class="mt-1" aria-hidden="true" style="width: 16px; height: 16px"></component>
								<span class="mt-0">{{ slotProps.option.categoryName }}</span>
							</div>
						</template>
					</Dropdown>
				</div>
				<div style="grid-column-start: 2; grid-column-end: -1; padding: 5px">
					<label for="image" class="formLabel">Media</label>
					<FileUpload
						mode="basic"
						name="fUpload"
						ref="fUpload"
						:customUpload="true"
						@uploader="submitFile($event)"
						:chooseLabel="labelText"
						v-if="!file && !newsDetails.imageFileUri && renderComponent"
						@change="getFileName($event)"
					/>
					<div class="flex gap-1 mt-2 justify-left" v-if="file">
						<span class="label-text">{{ imageFileName }}</span>
						<a href="#" @click="deleteFile()" class="hover:cursor-pointer;" data-bs-toggle="tooltip" title="Delete image file"
							><TrashIcon style="width: 16px; height: 16px" class="mt-1"></TrashIcon
						></a>
					</div>
					<div class="flex gap-2 mt-1 justify-left" v-if="newsDetails.imageFileUri && renderComponent">
						<a target="_blank" :href="decodeURIComponent(newsDetails.imageFileUri)" v-text="this.newsDetails.image" class="text-blue-600" />
						<a href="#" @click="deleteFile()" class="hover:cursor-pointer;" data-bs-toggle="tooltip" title="Delete image file"
							><TrashIcon style="width: 16px; height: 16px" class="mt-1"></TrashIcon
						></a>
					</div>
				</div>
				<div style="grid-column-start: 1; grid-column-end: -1; padding: 5px">
					<label for="newsContent" class="formLabel">Content</label>
					<Editor
						v-model="newsDetails.newsContent"
						id="newsContent"
						:autoResize="false"
						editorStyle="height: 150px"
						class="formText"
						:class="{ invalid: saveAttempted && !newsDetails.newsContent }"
					></Editor>
					<!-- <Textarea
						v-model="newsDetails.newsContent"
						:autoResize="false"
						rows="9"
						id="newsContent"
						class="formText"
						:class="{ invalid: saveAttempted && !newsDetails.newsContent }"
						style="overflow: hidden; overflow-y: auto"
					/> -->
				</div>
				<div style="grid-column-start: 1; grid-column-end: -1; padding: 5px">
					<label for="audiences" class="formLabel">Audience</label>
					<div class="flex flex-row gap-6 mt-2">
						<div class="p-field-checkbox">
							<input type="checkbox" id="checkboxSuperUserChannel" v-model="newsDetails.emailSuperUserChannel" />
							<label> Teams Super User Channel</label>
						</div>
						<div class="p-field-checkbox">
							<input type="checkbox" id="checkboxAdmins" v-model="newsDetails.emailAdmins" />
							<label> Admins</label>
						</div>
						<div class="p-field-checkbox">
							<input type="checkbox" id="checkboxErmCRISPUsers" v-model="newsDetails.emailErmCRISPUsers" />
							<label> ERM Climate Impact Platform Users</label>
						</div>
						<div class="p-field-checkbox">
							<input type="checkbox" id="checkboxCustomerCRISPUsers" v-model="newsDetails.emailCustomerCRISPUsers" />
							<label> Customer Climate Impact Platform Users</label>
						</div>
						<div class="p-field-checkbox">
							<input type="checkbox" id="checkboxnewsFeed" checked="true" v-model="newsDetails.emailnewsFeed" />
							<label> News Feed</label>
						</div>
					</div>
				</div>
			</div>
		</form>

		<!-- <ConfirmDialog></ConfirmDialog> -->
		<Toast id="newsToast" />
		<div class="flex justify-between w-full gap-2 mt-4" v-if="newsDetails.id">
			<span class="text-xs style1"
				>Created On: <b>{{ formatDate(newsDetails.createdDate) }}</b></span
			>
		</div>
	</div>
</template>

<script lang="ts">
import axios from "axios"
import Textarea from "primevue/textarea"
import FileUpload from "primevue/fileupload"
import Calendar from "primevue/calendar"
import { defineComponent } from "vue"
import { ApiClient, News, NewsCategory } from "@/../entities/NSwagGenerated"

import { v4 as uuidv4 } from "uuid"
import Toast from "primevue/toast"
import Dropdown from "primevue/dropdown"
import { SessionInfo, PageAccess } from "@/auth/personSessionData"
import "primeicons/primeicons.css"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import PageLabel from "@/components/pageLabel.vue"
import Editor from "primevue/editor"
import Checkbox from "primevue/checkbox"

// import HeroIcon from "vue-heroicons"
// import { archive, arrowDown } from "vue-heroicons/src/icons"

// HeroIcon.add([archive, arrowDown])

import {
	AdjustmentsHorizontalIcon,
	AcademicCapIcon,
	SpeakerWaveIcon,
	TrashIcon,
	ChartPieIcon,
	ExclamationCircleIcon,
} from "@heroicons/vue/24/outline"

//fontawesome
import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core"
//import { from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

export default defineComponent({
	name: "newsForm",
	data() {
		const apiClient = new ApiClient(import.meta.env.VITE_API_URL?.toString())
		let saveAttempted = false
		let processing = false
		let file = null
		let labelText = "Upload Image"
		const catgeoryList = new Array<NewsCategory>()
		let categories1: any = []

		let faLibraryIcons = []
		let selectedCategory = new NewsCategory()
		let imageFileName = ""

		return {
			apiClient,
			saveAttempted,
			catgeoryList,
			categories1,
			processing,
			file,
			labelText,
			renderComponent: true,
			releaseDate: null,
			expirationDate: null,
			categoryId: null,

			faLibraryIcons,
			selectedCategory,
			imageFileName,
		}
	},

	props: {
		newsDetails: {
			type: News,
			required: true,
		},
	},
	emits: ["cancel", "refreshList", "updateNewsDetails"],

	components: {
		Dropdown,
		Toast,
		Button,
		InputText,
		Checkbox,
		PageLabel,
		Textarea,
		FileUpload,
		AdjustmentsHorizontalIcon,
		AcademicCapIcon,
		SpeakerWaveIcon,
		TrashIcon,
		Calendar,
		ChartPieIcon,
		ExclamationCircleIcon,
		Editor,
		FontAwesomeIcon,
	},
	created() {},

	mounted() {
		this.loadCategories()
		if (this.newsDetails.id) {
			this.releaseDate = new Date(this.newsDetails.releaseDate)
			this.expirationDate = new Date(this.newsDetails.expirationDate)
			this.categoryId = this.newsDetails.categoryId
			// console.log("dates:", this.releaseDate, this.expirationDate)

			if (this.newsDetails.imageFileUri) {
				this.imageFileName = this.newsDetails.image
				this.fileWebPresent = true
				this.filePresent = false
			}
		} else {
			this.newsDetails.emailnewsFeed = true
		}
	},
	methods: {
		setValue(categoryId) {
			this.selectedCategory = this.catgeoryList.find((c) => c.id == categoryId)
		},
		formatDate(dateValue) {
			if (dateValue) {
				return new Date(dateValue.toString()).toLocaleDateString("en-US")
			}
		},
		deleteFile() {
			if (this.newsDetails.imageFileUri) {
				let formData = new FormData()
				formData.append("fileName", this.newsDetails.image)
				formData.append("newsId", this.newsDetails.id)
				axios
					.post(import.meta.env.VITE_API_URL?.toString() + "/api/deleteImageFile", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							authorization: "bearer " + sessionStorage.getItem("token"),
						},
					})
					.then((response) => {
						this.$toast.add({ severity: "success", summary: "File Deleted Successfully", life: 3000 })

						this.file = null

						this.newsDetails.imageFileUri = null
						this.renderComponent = false
						this.$nextTick(() => {
							this.renderComponent = true
						})
					})
					.catch((err) => {
						this.$toast.add({ severity: "error", summary: "Error Saving", detail: "Tell Support:" + err.message })
					})
			} else {
				this.file = null
			}
		},
		loadCategories() {
			this.apiClient.categoryAll().then((res) => {
				this.catgeoryList = res

				if (this.newsDetails.id) {
					this.setValue(this.newsDetails.categoryId)
				}
			})
		},
		cancel() {},
		validateRequired(obj: any, defaultFields: Array<string>) {
			let noMissingFields = true
			let noDateAnomalies = true
			let message = ""

			defaultFields.forEach((field) => {
				if (!obj[field] || obj[field]?.length == 0) {
					noMissingFields = false
				}
			})

			if (!noMissingFields) {
				this.$toast.add({ severity: "error", summary: "Highlighted fields are required", life: 3000 })
			} else {
				if (obj["expirationDate"] <= obj["releaseDate"]) {
					message = "Expiration Date should be later than Release Date"
					noDateAnomalies = false
				} else {
					if (!this.newsDetails.id) {
						let createdDt = new Date(obj["createdDate"])
						let relDate = new Date(obj["releaseDate"])
						createdDt = new Date(createdDt.getUTCFullYear(), createdDt.getUTCMonth(), createdDt.getUTCDate())
						relDate = new Date(relDate.getUTCFullYear(), relDate.getUTCMonth(), relDate.getUTCDate())
						console.log("Created Date vs. Release Date", createdDt, relDate)
						if (createdDt > relDate) {
							message = "Release Date should be later than or same as Created Date"
							noDateAnomalies = false
						}
					}
				}
			}

			if (!noDateAnomalies) {
				this.$toast.add({ severity: "error", summary: message })
			}

			// }

			return noMissingFields && noDateAnomalies
		},
		getFileName(evt: any) {
			let ext = this.$refs.fUpload.files[0].name.split(".")[1].toLowerCase()

			if (ext != "png" && ext != "jpeg" && ext != "jpg" && ext != "gif") {
				console.log("false value", ext)

				this.$toast.add({ severity: "error", summary: "Invalid File Extension. Allowed file extensions are jpg/jpeg, gif, png" })
				this.renderComponent = false
				this.$nextTick(() => {
					this.renderComponent = true
				})
			} else {
				this.file = this.$refs.fUpload.files[0]
				this.imageFileName = this.file.name
			}
		},
		submitFile(event: any) {
			if (event.files[0] != null) {
				let ext = event.files[0].name.split(".")[1].toLowerCase()

				if (ext != "png" && ext != "jpeg" && ext != "jpg" && ext != "gif") {
					this.status = "You can't upload ." + ext + " type of file."
					event.files[0] = null
					//to refresh upload control
					this.renderComponent = false
					this.$nextTick(() => {
						this.renderComponent = true
					})
					//to refresh upload control
					return
				} else {
					this.file = event.files[0]
					this.newsDetails.image = this.file.name
				}
			}
		},

		save() {
			this.saveAttempted = true

			this.newsDetails.releaseDate = this.releaseDate
			this.newsDetails.expirationDate = this.expirationDate
			this.newsDetails.categoryId = this.categoryId
			this.newsDetails.createdBy = SessionInfo.currentUser().id
			if (!this.newsDetails.id) {
				this.newsDetails.createdDate = new Date()
			}

			if (!this.validateRequired(this.newsDetails, ["headline", "releaseDate", "expirationDate", "categoryId", "newsContent"])) {
				return
			}

			let formData = new FormData()

			// if (this.newsDetails.emailnewsFeed == false) {
			// 	this.newsDetails.releaseDate = this.newsDetails.expirationDate
			// }

			if (this.file != null) {
				formData.append("file", this.file)
				this.newsDetails.image = this.file.name
			}

			if (!this.newsDetails.id) {
				this.newsDetails.id = uuidv4()

				formData.append("newsDetails", JSON.stringify(this.newsDetails))
				axios
					.post(import.meta.env.VITE_API_URL?.toString() + "/api/news", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							authorization: "bearer " + sessionStorage.getItem("token"),
						},
					})
					.then((response) => {
						this.$toast.add({ severity: "success", summary: "Article Saved Successfully", life: 3000 })

						this.$emit("refreshList")
						setTimeout(() => this.$emit("updateNewsDetails", response.data), 3000)

						console.log("image file name for saving", this.imageFileName)
					})
					.catch((err) => {
						this.$toast.add({ severity: "error", summary: "Error Saving", detail: "Tell Support:" + err.message })
					})
			} else {
				formData.append("newsDetails", JSON.stringify(this.newsDetails))
				formData.append("id", this.newsDetails.id)
				console.log(this.newsDetails.id)
				axios
					.put(import.meta.env.VITE_API_URL?.toString() + "/api/news", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							authorization: "bearer " + sessionStorage.getItem("token"),
						},
					})
					.then((response) => {
						this.$toast.add({ severity: "success", summary: "Article Updated Successfully", life: 3000 })
						setTimeout(() => this.$emit("updateNewsDetails", response.data), 3000)

						this.$emit("refreshList")
						console.log("after Update imagefilUri and file", this.newsDetails.imageFileUri, this.file)
					})
					.catch((err) => {
						this.$toast.add({ severity: "error", summary: "Error Saving", detail: "Tell Support:" + err.message })
					})
			}
		},
	},
})
</script>

<style scoped>
.invalid {
	border-color: red;
	border: solid 1px red;
}

.style1 {
	color: gray !important;
	font-size: 0.7rem !important;
}
</style>
