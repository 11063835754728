import { Screen, UserType, UserTypeScreen } from "@/../entities/NSwagGenerated"
import { Auth0Client, Auth0ClientOptions } from "@auth0/auth0-spa-js"
import { appAuth } from "../auth/appAuth"

export enum PageAccess {
	Create,
	Read,
	Update,
	Archive,
	Delete,
}

export class PersonSessionData {
	id: string
	firstName: string
	lastName: string
	fullName: string
	email: string
	isAdmin: boolean
	screens: UserTypeScreen[]
	role: string
}

export abstract class SessionInfo {
	public static async checkAuth0Status(): Promise<Boolean> {
		const auth0Options: Auth0ClientOptions = {
			client_id: import.meta.env.VITE_AUTH0_CLIENT_KEY,
			domain: import.meta.env.VITE_AUTH0_DOMAIN,
			audience: import.meta.env.VITE_AUTH0_AUDIENCE,
			redirectUri: window.location.origin,
		}
		const client = new Auth0Client(auth0Options)

		let loginResult = client.loginWithPopup().then(async () => {
			//user.value = auth0.user
			//if (auth0.isAuthenticated.value) {

			const token = await client.getTokenSilently()

			if (token) {
				sessionStorage.setItem("token", token)

				let result = await appAuth.loginToAppWithToken(token).then((callback) => {
					if (callback != "ok") {
						sessionStorage.setItem("error", callback)
						return false
						//logout()
					} else {
						return true
					}
				})
				return result
			} else {
				return false
			}
		})

		return true
	}

	public static currentUser(): PersonSessionData {
		//if (!sessionStorage.getItem("PersonSessionData")) SessionInfo.checkAuth0Status()
		const sessionData: PersonSessionData = JSON.parse(sessionStorage.getItem("PersonSessionData"))
		return sessionData
	}

	public static currentUserIsAdmin(): boolean {
		//if (!sessionStorage.getItem("PersonSessionData")) SessionInfo.checkAuth0Status()
		const sessionData: PersonSessionData = JSON.parse(sessionStorage.getItem("PersonSessionData"))
		return sessionData.isAdmin
	}

	public static currentUsergetScreens(): UserTypeScreen[] {
		//if (!sessionStorage.getItem("PersonSessionData")) SessionInfo.checkAuth0Status()
		const sessionData: PersonSessionData = JSON.parse(sessionStorage.getItem("PersonSessionData"))
		return sessionData.screens
	}

	public static hasRightsToScreen(screen: string, access: PageAccess): boolean {
		//if (!sessionStorage.getItem("PersonSessionData")) await SessionInfo.checkAuth0Status()
		let sessionStoragePersonData = sessionStorage.getItem("PersonSessionData")

		if (sessionStoragePersonData == null) {
			return false
		}

		const sessionData: PersonSessionData = JSON.parse(sessionStoragePersonData)
		const screenRights = sessionData.screens.find((s) => s.screen.screenName == screen)
		if (screenRights != undefined && screenRights.accessType != null) {
			// console.log(
			// 	"rights check:",
			// 	PageAccess[access].toString().slice(0, 1),
			// 	"screen:",
			// 	screen,
			// 	"access",
			// 	screenRights.accessType,
			// 	"granted?:",
			// 	screenRights.accessType.indexOf(PageAccess[access].toString().slice(0, 1)) >= 0
			// )
			return screenRights.accessType.indexOf(PageAccess[access].toString().slice(0, 1)) >= 0
		} else {
			console.error(`unable to find access rights for screen ${screen}`)
			return false
		}
	}
}
