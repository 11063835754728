import { defineStore } from "pinia"
import { ref } from "vue"

export type ConnectionStatus = "connected" | "disconnected" | "reconnecting" | "connecting" | "error"

export const useRealtimeCommunicationStore = defineStore("realtimeCommunication", () => {
	const aiConnection = ref<ConnectionStatus>("disconnected")
	const apiConnection = ref<ConnectionStatus>("disconnected")

	const setAiConnection = (status: ConnectionStatus) => {
		aiConnection.value = status
	}

	const setApiConnection = (status: ConnectionStatus) => {
		apiConnection.value = status
	}

	return { aiConnection, apiConnection, setAiConnection, setApiConnection }
})
