<template>
	<div class="flex flex-col" id="Contacts">
		<form @submit.prevent="save()">
			<div class="w-full justify-between mb-4 flex gap-2">
				<h1 class="font-bold text-2xl text-primary">Add Contact</h1>
				<span class="flex gap-2">
					<button type="button" @click="$emit('cancel')" class="btn bg-redSignal">Back to List</button>
					<button class="btn btn-primary" v-if="SessionInfo.hasRightsToScreen('Contacts', PageAccess.Update)">Save</button>
				</span>
			</div>
			<div class="grid lg:grid-cols-2 gap-4">
				<div style="grid-column-start: 1; grid-column-end: 2">
					<label for="firstName" class="formLabel"> First Name </label>
					<input type="text" ref="firstName" v-model="person.firstName" :class="{ invalid: saveAttempted && !person.firstName }" class="w-full" />
				</div>
				<div style="grid-column-start: 2; grid-column-end: -1">
					<label for="lastName" class="formLabel"> Last Name </label>
					<input type="text" ref="lastName" v-model="person.lastName" :class="{ invalid: saveAttempted && !person.lastName }" class="w-full" />
				</div>
				<div style="grid-column-start: 1; grid-column-end: 2">
					<label for="emailid" class="formLabel"> Email </label>
					<input type="email" ref="emailAddress" v-model="person.emailid" :class="{ invalid: saveAttempted && !person.emailid }" class="w-full" />
				</div>
			</div>
		</form>

		<Toast position="top-right" />
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { ApiClient, Person } from "@/../entities/NSwagGenerated"

import { v4 as uuidv4 } from "uuid"
import Toast from "primevue/toast"
import { SessionInfo, PageAccess } from "@/auth/personSessionData"
import { useFormSecurity } from "@/composables/formSecurity"

export default defineComponent({
	name: "contactForm",
	data() {
		const apiClient = new ApiClient(import.meta.env.VITE_API_URL?.toString())
		let saveAttempted = false

		return { apiClient, saveAttempted, SessionInfo, PageAccess }
	},
	emits: ["cancel", "refreshList"],
	props: {
		person: {
			type: Person,
			required: true,
		},
	},
	components: {},
	mounted() {
		useFormSecurity().applyPageAccessSecurity("Contacts")
	},
	methods: {
		cancel() {},
		validateRequired(obj: any, defaultFields: Array<string>) {
			let noMissingFields = true

			defaultFields.forEach((field) => {
				if (!obj[field] || obj[field]?.length == 0) {
					noMissingFields = false
				}
			})

			if (!noMissingFields) {
				this.$toast.add({ severity: "error", summary: "Highlighted fields are required", life: 2000 })
			}

			return noMissingFields
		},
		save() {
			this.saveAttempted = true

			if (!this.validateRequired(this.person, ["firstName", "lastName", "emailid"])) {
				return
			}
			if (!this.person.id) {
				//if nulll, create new
				this.person.id = uuidv4()
				this.apiClient.postContact(this.person).then(
					(res) => {
						this.$toast.add({ severity: "success", summary: "Saved", life: 3000 })
					},
					(err) => {
						this.$toast.add({ severity: "error", summary: "Error Saving", detail: "Tell Support:" + err.message })
					}
				)
			} else {
				this.apiClient.userPut(this.person.id, this.person).then(
					(res) => {
						this.$toast.add({ severity: "success", summary: "Saved", life: 3000 })
					},
					(err) => {
						this.$toast.add({ severity: "error", summary: "Error Saving", detail: "Tell Support:" + err.message })
					}
				)
			}
			this.$emit("refreshList")
		},
	},
	computed: {},
})
</script>

<style scoped>
.invalid {
	border-color: red;
}
</style>
