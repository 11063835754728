<template>
	<div class="field" :class="{ split: split, right: right, left: left, row: row }">
		<label for="fieldName" :class="{ invalidForm: isValid, required: required }">{{ label }}</label>
		<slot><div class="warning">Add a control Here</div></slot>
	</div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue"

const slots = defineSlots<{
	default(): any
}>()

const slotData = ref("test")
onMounted(() => {})

const props = defineProps({
	label: {
		type: String,
		Array,
		required: false,
	},
	i18nName: {
		type: String,
		default: "",
	},
	helpElementName: {
		type: String,
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	split: {
		type: Boolean,
	},
	right: {
		type: Boolean,
	},
	left: {
		type: Boolean,
	},
	row: {
		type: Boolean,
	},
	isValid: {
		type: Boolean,
		default: false,
	},
})
</script>

<style scoped>
.required::after {
	content: "*";
	color: red;
}
.invalidForm {
	color: blue;
}
</style>
