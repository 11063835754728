<template>
	<div class="flex flex-row gap-4 items-start mr-4">
		<h1 ref="pageLabelSlot">
			<slot></slot>
		</h1>
		<button @click.prevent="getHelp" v-tooltip="'View Page Help'" aria-label="View Page Help" class="largeheader">
			<FontAwesomeIcon :icon="['far', 'circle-info']" />
		</button>
	</div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { ApiClient } from "@/../entities/NSwagGenerated"
import { EventBus } from "@/eventBus"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
library.add(faCircleInfo)

export default defineComponent({
	name: "PageLabel",
	props: {
		helpPageName: {
			type: String,
			required: true,
		},
	},
	components: { FontAwesomeIcon },
	mounted() {},
	methods: {
		getHelp() {
			EventBus.emit("OpenPageHelp", { helpPageName: this.helpPageName, userFriendlyName: (this.$refs.pageLabelSlot as HTMLElement).innerHTML })
		},
	},
	computed: {},
})
</script>

<style scoped></style>
