<template>
	<div id="app" class="">
		<router-view />
	</div>
</template>
<style>
/* uncommenttooutlineeverything {
	outline: 1px solid #f00 !important;
} */
</style>
<script lang="ts">
import { reactive, defineComponent } from "vue"

import "primevue/resources/themes/saga-blue/theme.css" //theme
import "primevue/resources/primevue.min.css" //core css
import "primeicons/primeicons.css" //icons
import "vue-json-pretty/lib/styles.css"

export default defineComponent({
	name: "App",
	setup() {},
})
</script>
