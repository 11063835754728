import { EventBus } from "./eventBus"

export default class DialogContent {
	header: string
	content: string
	footer: string
	isModal: boolean = false
	dialogType: DialogType = DialogType.Message
	position: "left" | "right" | "top" | "center" | "bottom" | "topleft" | "topright" | "bottomleft" | "bottomright" | undefined
}

export enum DialogType {
	Message,
	Warning,
	Error,
}

export function openDialog(d: DialogContent) {
	EventBus.emit("ShowDialog", d)
}
export function openLoginDialog(d: DialogContent) {
	EventBus.emit("ShowLoginDialog", d)
}
